import axios from 'axios'
import Vue from 'vue'
import { Toast } from 'vant';
/**
 * 监听请求错误
 * @param error
 * @returns {*}
 */

function handleError (error) {
    //错误提示
    if (error.response) {
        console.groupCollapsed('request error')
        console.log('url:', error.response.config.url)
        console.log('status:', error.response.status)
        console.log('headers:', error.response.headers)
        console.log('data', error.response.data)
        console.log('message', error.response.data.message)
        console.groupEnd()


        let status = error.response.status
        let message =  error.response.data.message;
        
        switch (status) {
            case 504:
            message = '504接口访问不成功！';
            break;
            case 404:
            message = '404资源不存在';
            break;
            case 400:
            message = error.response.data.message;
            break;
            case 401:
            message = "未授权";
            break;
            case 403:
            message = "服务器禁止访问";
            break;
            case 502:
            message = "502服务器走丢啦";
            break;
            case 500:
            message = error.response.data.message;
            break;
        }
        Toast.fail(message);
    } else if (error.request) {
        console.log(error.request)
    } else {
        console.log('Error', error.message)
    }
}

Vue.prototype.$baseURL = process.env.VUE_APP_API_URL;
console.log(process.env.VUE_APP_API_URL);


axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers['Content-Type'] = 'application/json'

// 请求发起前拦截器
axios.interceptors.request.use((config) => { 
    let url = config.url
    if(url.indexOf('/pay/weixinPayResult') == -1){
        Toast.loading({
        message: '加载中',
        forbidClick: true,
        duration:0
        });
    }
    return config
})

// 响应拦截
// 处理接口缓存/接口报错上传
axios.interceptors.response.use((response) => {
    console.groupCollapsed('request success')
    console.log('url:', response.config.url)
    console.log('data', response.data)
    console.log('status:', response.status)
    console.groupEnd()

    let data = response.data
    // if(response.config.url == '' || response.config.url == ''){
    //     data = response.data
    // }else{
    //     data = JSON.parse(tools.Decrypt(response.data))
    // }

    if (!(data instanceof Object)) {
        Toast.clear();
        return Promise.resolve(data)
    }
    if (data.code == 200 || data.repCode) {
        Toast.clear();
        return Promise.resolve(data)
    }else {
        // 充值后不弹出错误框
        let url = response.config.url
        if(url.indexOf('/pay/weixinPayResult') == -1){
            Toast(data.msg || data.repMsg);
        }
        return Promise.reject(new Error(data.msg || data.repMsg))
    }
}, (error) => {
    handleError(error)
    return Promise.reject(new Error(error))
})

export default axios
