<template>
  <div class="home" id="recharge">
    <div class="top bg_white padding_15">
      <div class="flex flex_between flex_center">
        <div class="icon_box">
          <div class="icon flex_center" v-if="headImage == '' || headImage == null">
            <i class="iconfont device">&#xe6c9;</i>
          </div>
          <div class="icon flex_center" v-else>
            <van-image round width="60" height="60" :src="headImage" />
          </div>
        </div>
        <div class="info ">
          <div class="SN flex_left flex_center">
            <div class="txt">设备编号:</div>
            <div class="NO">{{ deviceInfo.sn }}</div>
            <div class="copy" @click="copy">
              <i class="iconfont">&#xe8b0;</i>
            </div>
          </div>
          <div v-if="deviceInfo.authentication !== 0">
            <div class="date" v-if="!deviceInfo.serveSurplusDay == 0">有效期至: <span></span>{{deviceInfo.serveValidDate }}
            </div>
            <div class="date" v-else>有效期至: <span class="red">已过期（续费后立即开通网络）</span></div>
          </div>
          <div class="tips flex_left">
            <span
                  :class=" deviceInfo.deviceStatus == 1 ? 'green' : 'red'">{{deviceInfo.deviceStatus == 0 ? '设备离线' : deviceInfo.deviceStatus == 1 ? '设备在线' : '无设备状态信息'}}</span>
            <span v-if="deviceInfo.authentication !== null || deviceInfo.authentication !== '' || deviceInfo.authentication !== undefined "
                  :class=" deviceInfo.authentication == 1 ? 'green' : 'red'">{{ deviceInfo.authentication == 1 ? '已实名' :   deviceInfo.authentication == 0 ? '未实名' : '无实名信息'}}</span>
          </div>
        </div>
      </div>
      <div class="flex_between top_info">
        <div class="box">
          <div class="t1">{{deviceInfo.packetUsedFlowStr || 0}}</div>
          <div class="txt">套餐使用流量</div>
        </div>
        <div class="box">
          <div class="t1">{{deviceInfo.serveSurplusDay || 0}}</div>
          <div class="txt">剩余时长(天)</div>
        </div>
      </div>
    </div>
    <!-- 通知 -->
    <div class="scroll">
      <van-notice-bar left-icon="volume-o" :scrollable="false" background="#fffbe800" color="#6a4526">
        <van-swipe vertical class="notice-swipe" :autoplay="2000" :show-indicators="false">
          <van-swipe-item v-for="(item, i) in swipeList" :key="i">{{ item }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <!-- 套餐选择 -->
    <div v-if="list.length > 0"
         style="background: #fff; border-radius: 5px; padding: 10px 20px 20px 20px; margin-top: 10px;">
      <div class="title">
        <span class="font-weight_5">套餐选择 </span>
        <!-- <span class="red">（测试缓存）</span> -->
      </div>
      <!--套餐列表-->
      <div class="item_list">
        <div class="item " v-for="item in list" :key="item.id" :class="radio == item.id ? 'select' : 'hide-radio'"
             @click="select(item)">
          <div class="hot" v-if="item.hotWords">
            {{item.hotWords}}
          </div>
          <div class="box flex_center" :class="item.hotWords ? 'hot-align' : ''">
            <div class="font-center">
              <div class="title">{{item.packetName}}</div>
              <div class="price">¥{{item.packetPrice}}</div>
              <!-- <div class="rule">{{item.packetRule}}</div> -->
              <div class="remark" v-if="item.remark">
                {{item.remark}}
              </div>
            </div>
          </div>
          <div class="radio flex_center">
            <van-icon name="success" color="#fff" size="10" />
          </div>
        </div>
      </div>
      <!--购买按钮-->
      <div class="buy_btn">
        <van-button class="btn" round type="info" @click="gotoPayDetails"
                    color="linear-gradient(to right, #649ffd, #649ffd)">购买</van-button>
      </div>
    </div>
    <div v-else>
      <van-empty description="该商户暂无可充值的套餐哦"></van-empty>
    </div>
    <!--确认订单-->
    <!-- <van-popup v-model="show" round position="bottom" :style="{ height: '380px' }" class="pop_box">
          <div class="title">确认订单</div>
          <div class="border flex_between">
              <span>ICID卡号</span>
              <span>8989898989</span>
          </div>
          <div  class="border flex_between">
              <span>套餐名称</span>
              <span>3000G/月*12月</span>
          </div>
          <div  class="border flex_between">
              <span>套餐价格</span>
              <span class="price">¥499</span>
          </div>
          <div class="flex_between">
              <span>支付方式</span>
              <span class="flex_center"><van-icon name="wechat-pay" color="#58B337" size="30"/>微信支付</span>
          </div>
          <van-button class="pay_btn" size="large"   round type="info" @click="payStatus" color="linear-gradient(to right, #649ffd, #649ffd)" >确认支付（¥ {{ list[radio - 1].price}}）</van-button>
      </van-popup> -->
  </div>
</template>
<script>



  export default {
    name: 'HomeView',

    data () {
      return {
        // show:false,
        list: [], //套餐列表
        radio: '', //所选的套餐ID
        packetName: '',//所选的套餐名称
        equipmentSn: '',

        //埋点
        browseTime: 0,  // 浏览时长初始值为 0
        clearTimeSet: null,  //定时器
        businessName: "套餐列表", //业务名称
        exitTime: "",//离开时间
        hostName: "",//HOST
        ipAddress: '',//IP
        isPlaceOrder: 0,//是否下单
        placeOrderTime: '',//下单时间
        userName: "",//用户名
        stopTime: "",//停留秒
        visitTime: "", //访问时间

        deviceInfo: {},//设备信息
        headImage: '',

        swipeList: []
      }
    },
    metaInfo () {
      return {
        title: '流量充值',
      };
    },
    mounted () {

      this.getRechargeList()
      // window.addEventListener('pagehide', () => {
      //   alert("pagehide")
      // })
      this.$publicFun.getTicket(this.onloadFun)
    },

    activated () {
      console.log("activated")
    },

    deactivated () {
      console.log("deactivated")
    },

    errorCaptured () {
      console.log("errorCaptured")
    },

    beforeDestroy () {
      this.exitTime = this.$publicFun.getTime()
      clearInterval(this.clearTimeSet); // 离开页面后清除定时器
      let data = {
        businessName: "套餐列表", //业务名称
        exitTime: this.exitTime,//离开时间
        hostName: location.hostname,//HOST 
        ipAddress: '',//IP meiyou
        isPlaceOrder: this.isPlaceOrder,//是否下单 meiyou
        placeOrderTime: '',//下单时间  meiyou
        openid: this.$publicFun.getUserInfo().openid,
        userName: this.$publicFun.getUserInfo().nickName,//用户名
        stopTime: this.browseTime,//停留秒
        visitTime: this.visitTime, //访问时间
      }
      console.log(data)
      //提交埋点数据
      this.$request.saveUsertrack(data).then(res => {
        console.log(res)
      })
    },


    methods: {
      //复制
      copy () {
        //创建input标签
        var input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = this.deviceInfo.sn;
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //成功提示信息
        this.$toast('复制成功')
        //移除input标签
        document.body.removeChild(input)
      },
      //登录回调
      onloadFun (userInfo) {


        console.log(userInfo)
        this.headImage = userInfo.headImage
        this.setTime();
        this.$request.getEquipmentList(userInfo.id).then(res => {
          if (res.data.length == 0) {
            this.$dialog.alert({
              title: '提示',
              message: '您还暂未绑定设备,去绑定设备',
            }).then(() => {
              this.$router.push({ name: 'Bind', query: { appId: this.$publicFun.getUrlappId() } })
            });
            return
          } else {
            this.getRechargeList()
          }
        })

        this.$request.getOrderSwipeList().then(res => {
          this.swipeList = res.data
        })
      },
      //前往支付详情页面
      gotoPayDetails () {
        this.$router.push({ name: 'PayDetails', query: { radio: this.radio, packetName: this.packetName, equipmentSn: this.equipmentSn, appId: this.$publicFun.getUrlappId() } })
      },

      // 获取套餐
      getRechargeList () {

        if (this.$route.query && this.$route.query.equipmentSn) {
          this.equipmentSn = this.$route.query.equipmentSn
        }
        // 查询设备信息
        this.$request.getEquipmentInfo({sn: this.equipmentSn}).then(res => {
          this.deviceInfo = res.data
        })
        // 查询套餐
        this.$request.getRechargeListBT().then(res => {
          this.list = res.data
          if (this.list.length > 0) {
            this.radio = this.list[0].id
            this.packetName = this.list[0].packetName
          }
        })
      },

      setTime () {
        //设置定时器
        this.visitTime = this.$publicFun.getTime()
        this.clearTimeSet = setInterval(() => {
          this.browseTime++;
          // console.log(this.browseTime, "时长累计");
        }, 1000);
      },

      // payStatus(){
      //   this.$router.push({name:'PayStatus'})
      // },

      select (item) {
        this.radio = item.id
        this.packetName = item.packetName
      },
    },
  }
</script>

<style lang="scss" scoped>
  .home {
    height: 100vh;
    background-color: #fdddc8;
  }

  .top {
    background-image: url(@/assets/img/bg_card.jpg);
    border-radius: 5px;
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 60px;
    position: relative;
    // padding-bottom: 40px;
    // min-height:150px;

    .btn {
      display: flex;
      align-items: center;
      padding: 10px;
      padding-right: 0;
      background-color: #0f85e4;
      background-image: linear-gradient(
        77deg,
        #0f85e4 13%,
        rgba(220, 245, 255, 0.68)
      );
      border-bottom-left-radius: 35px;
      border-top-left-radius: 35px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      position: absolute;
      right: 0px;
      top: 40px;

      img {
        width: 20px;
        margin-right: 5px;
      }
    }

    .top_info {
      font-size: 18px;
      color: #513f23;
      text-align: center;
      padding: 20px 30px;
      padding-bottom: 0px;
      .txt {
        font-size: 14px;
        margin-top: 2px;
      }
      .t1 {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .icon_box {
      width: 20%;
    }
    .info {
      width: 80%;
    }
    .icon {
      width: 60px;
      height: 60px;
      text-align: center;
      // line-height: 60px;
      border-radius: 50%;
      border: 1px solid rgb(255, 255, 255);
    }

    .date {
      font-size: 12px;
      color: #ffffff;

      .red {
        color: #f21a06;
      }
    }

    .SN {
      font-size: 14px;
      .txt {
        font-size: 14px;
        color: #ffffff;
        margin-right: 10px;
      }
      .NO {
        margin-right: 10px;
        font-size: 14px;
        color: #ffffff;
        font-weight: 600;
      }
    }
    
    .tips {
      margin-top: 5px;
      span {
        background: #2fc207;
        color: #fff;
        font-size: 12px;
        padding: 0px 5px;
        border-radius: 5px;
        margin-right: 10px;
      }
      .green {
        background: #2fc207;
      }
      .red {
        background: #f21a06;
      }
    }
  }

  .scroll {
    background-image: url(@/assets/img/month.png);
    border-radius: 5px;
    background-size: cover;
    margin-top: -50px;
    padding: 30px 5px 5px 5px;
    position: relative;

    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
  }

  #recharge {
    padding: 20px;
    padding-top: 20px;
  }
 .item {
    display: flex;
    width: 47%;
    height: 175px;
    border: 3px solid #dedede;
    font-weight: bold;
    border-radius: 10px;
    // overflow: hidden;
    margin-bottom: 15px;
    text-align: center;
    position: relative;
    &.select {
      border-color: #c59f6d;
      .radio {
        opacity: 1;
      }
    }
    &.hide-radio {
      .radio {
        opacity: 0;
      }
    }
  }

  .item_list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 40px;

    .hot {
      background: #f53f3f;
      display: content;
      color: #fff;
      padding: 5px;
      font-size: 14px;
      width: 100%;
      position: absolute;
      border-radius: 5px 0 10px 0;
    }

    .hot-align {
      align-items: end !important;
    }

    .radio {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -2px;
      right: -2px;
      background: #c59f6d;
      border-radius: 0px 0px 12px 0px;
    }
  }

  .buy_btn {
    width: 100%;
    position: fixed;
    bottom: 25px;
    left: 0px;
    margin: auto;
  }

  .btn {
    width: 80%;
    margin-left: 10%;
    color: rgb(104, 52, 0) !important;
    font-size: 16px;
    background: linear-gradient(
      -82deg,
      rgb(225, 199, 142) 1%,
      rgb(249, 226, 182) 100%
    ) !important;
  }
  .title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;

    span {
      font-size: 16px;
      color: #333;
      line-height: 40px;
    }
  }
  .box {
    width: 100%;
    .title {
      color: #333;
      font-size: 14px;
      padding: 0 10px;
    }
    .price {
      color: #ee0a24;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .remark {
      font-size: 12px;
      font-weight: unset;
      color: #969799;
      width: 85%;
      margin: 0 auto;
      padding-bottom: 5px;
    }
    .rule {
      color: #969799;
      font-size: 12px;
    }
  }

  .pay_btn {
    color: #fff;
    width: 100%;
    margin-top: 10px;
  }

  .pop_box {
    padding: 20px;
    .title {
      text-align: center;
      font-size: 14px;
    }
    span {
      color: #101010;
      font-size: 14px;
      line-height: 36px;
    }
    .border {
      border-bottom: 1px solid #ddd;
    }
    .flex_between {
      display: flex;
      justify-content: space-between;
    }
    .van-button__text {
      color: #fff;
    }
  }

  // .red {
  //   color: #ee0a24 !important;
  // }
</style>


<style lang="scss">
  #recharge {
    .van-radio {
      position: relative;
      bottom: 0px;
      display: inline;
    }
    .van-radio__icon {
      position: absolute;
      bottom: -2px;
      right: -2px;
    }
  }
  .hide-radio {
    .van-radio__icon {
      display: none;
      opacity: 0;
    }
  }
</style>